<template>
	<header>
		<nav class="main fix-on-scroll" :class="page && page.headingImage ? '' : 'no-slider'">
			<div class="row" :class="{ active: showMenu }">
				<div class="columns column12 flex-row">
					<div v-if="showMenu" class="menu-button button" @click="hideMenu">
						<font-awesome-icon icon="fa-regular fa-times" size="1x" />
						<span>{{ $t('close') }}</span>
					</div>
					<div v-else class="menu-button button" @click="toggleMenu">
						<font-awesome-icon icon="fa-regular fa-bars" size="1x" />
						<span>{{ $t('menu') }}</span>
					</div>
					<nuxt-link
						class="logo"
						aria-label="Back to homepage"
						:to="{ path: localePath('/', locale), hash: route.name.startsWith('index') && '#top' }"
						@click="hideMenu"
					/>
					<div class="nav-buttons">
						<lang-select :items="page.langmenu" />

						<social-media theme="dark" :socials="socials.filter((item) => item.platform === 'whatsapp')" />
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`" class="mail-link">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<a
							class="button button-cta reserve-now right"
							data-guestline-redirect="true"
							aria-label="Reserve now"
							href="#"
						>
							<font-awesome-icon icon="fa-light fa-calendar-days" size="1x" />
							<span>{{ defaults[locale].website.book }}</span>
						</a>
					</div>
				</div>
			</div>
			<div class="nav-container" :class="{ active: showMenu }">
				<div class="row">
					<ul class="main-nav">
						<li
							v-for="item in topmenu[locale]"
							:key="item.filename"
							:class="{ 'has-dropdown': item.submenu.length }"
						>
							<nuxt-link :exact="item.slug === 'index'" :to="`${item.filename}`" @click="hideMenu">
								{{ item.header }}
							</nuxt-link>
							<submenu
								v-if="item.submenu.length"
								:hide-all="!showMenu"
								:item="item"
								:subitems="item.submenu"
								@hide-menu="hideMenu"
							/>
						</li>
						<li v-if="page" class="lang-nav">
							<div v-for="item in page.langmenu" :key="`${item.filename}-${item.language}`">
								<nuxt-link
									class="flag"
									:class="item.language"
									:to="`${item.filename}`"
									@click="hideMenu"
								>
									<span class="flag {{ item.language }}">{{ $t(item.language) }}</span>
								</nuxt-link>
							</div>
						</li>
					</ul>
					<div class="nav-contact">
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<font-awesome-icon icon="fa-light fa-phone" size="1x" />
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<font-awesome-icon icon="fa-light fa-envelope" size="1x" />
						</a>
						<social-media theme="dark" :socials="socials" />
					</div>
				</div>
			</div>
		</nav>
	</header>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, socials, fetchDefaults } = useDefaults();
const { locale } = useI18n();
const route = useRoute();

await fetchPage();
await fetchDefaults();

const showMenu = ref(false);

const { data: topmenu } = await useWebsiteFetch('topmenu');

const toggleMenu = () => {
	showMenu.value = !showMenu.value;
	document.body.classList.add('locked');
};

const hideMenu = () => {
	showMenu.value = false;
	document.body.classList.remove('locked');
};
</script>

<style lang="scss" scoped>
.logo {
	background: url('~/assets/images/logo-city-hotel-gouda-full.png') no-repeat center center;
	background-size: 110px;
	width: 110px;
	height: 172px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin: 0 auto;
	animation: fade-from-top 1s ease;
	transition: all 0.3s ease-in-out;
}

.row.active {
	.logo {
		background: url('~/assets/images/logo-city-hotel-gouda-icon.png') no-repeat center center;
		background-size: 44px;
		width: 44px;
		height: 68px;
		top: -10px;
	}

	.nav-buttons,
	:deep(.socials .whatsapp) {
		color: #000;

		a:not(.button) {
			color: #000;
		}
	}

	.menu-button {
		color: var(--header-color);
	}
}

nav.main {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
	padding: 30px 0;
	transition: all 0.3s ease-in-out;
	overflow: visible;

	&.scroll {
		color: #161616;

		.logo {
			background: url('~/assets/images/logo-city-hotel-gouda-icon.png') no-repeat center center;
			background-size: 44px;
			width: 44px;
			height: 68px;
			top: -10px;
		}

		.nav-buttons {
			color: #161616;

			a:not(.button),
			:deep(.socials .whatsapp) {
				color: #161616;
			}
		}

		.menu-button {
			span {
				color: var(--header-color);
			}

			svg {
				color: var(--cta-color);
			}
		}
	}
}

nav .row {
	overflow: visible;
	z-index: 102;
	position: relative;
}

nav.main.scroll,
nav.main.no-slider {
	padding: 18px;
	background: var(--primary-background-color);
	border-bottom: 3px solid var(--header-color);

	.nav-buttons {
		color: #161616;

		a:not(.button) {
			color: #161616;
		}
	}

	.menu-button {
		color: #161616;
	}

	.logo {
		background: url('~/assets/images/logo-city-hotel-gouda-icon.png') no-repeat center center;
		background-size: 44px;
		width: 44px;
		height: 68px;
		top: -10px;
	}
}

.reserve-now {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	padding: 10px 30px 9px 20px;

	svg {
		margin: -3px 12px 2px 0;
	}
}

.menu-button {
	background: none;
	padding: 0;
	margin: 0;
	font-size: 22px;
	font-weight: 600;
	font-family: var(--heading-font-family);
	cursor: pointer;
	letter-spacing: 0;
	text-align: left;
	animation: fade-from-left 0.7s ease;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	opacity: 0.85;
	text-transform: none;
	min-width: 0;

	svg {
		font-size: 30px;
		color: var(--cta-color);
	}

	span {
		margin: 2px 0 0;
	}

	&:hover {
		opacity: 1;
		background: none;
	}
}

nav .flex-row,
.nav-buttons {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.nav-buttons {
	gap: 22px;
	color: #fff;
	animation: fade-from-right 0.7s ease;

	a,
	:deep(.socials .whatsapp) {
		color: #fff;
		text-decoration: none;
		margin: 0;
		opacity: 0.85;

		&.mail-link {
			margin: 0.5px 0 -0.5px;
		}

		&.button,
		&:hover {
			opacity: 1;
		}
	}

	:deep(.socials .whatsapp) {
		margin-top: -2px;
		font-size: 18px;

		&:hover {
			color: #fff;
		}
	}
}

.reserve-now i {
	display: none;
}

.nav-container {
	background: #ffe8dd;
	background:
		url('~/assets/images/shapes/nav-top.png') no-repeat right top,
		url('~/assets/images/shapes/nav-bottom.png') no-repeat left bottom #ffe8dd;
	background-size: 48%;
	padding: 30px 50px;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 80px;
	z-index: 101;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.4s ease-in-out;
	transform: translate(0, 100%) matrix(1, 0, 0, 1, 0, 0);

	.row {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
}

.nav-container.active {
	transform: matrix(1, 0, 0, 1, 0, 0);
	opacity: 1;
}

.lang-nav {
	display: none;
	list-style: none;
	text-align: left;
	line-height: 26px;
	padding-top: 40px;

	a {
		color: #fff;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: center;
		font-size: 16px !important;
		gap: 5px;

		&.router-link-exact-active,
		&:hover {
			color: var(--cta-color);
		}
	}

	img {
		max-width: 16px;
		float: left;
		margin: 0 5px 0 0;
	}
}

.mobile-lang-nav {
	margin: 40px 0 0;
	font-size: 14px;
	display: none;
}

.mobile-lang-nav img {
	max-width: 16px;
	margin: 0 10px 0 0;
}

.main-nav .mobile-lang-nav a {
	width: 100%;
	display: block;
	font-size: 16px;
	line-height: 28px;
}

/* Fullscreen Nav */
.main-nav {
	list-style: none;
	min-width: 25%;
	text-align: center;
	padding-top: 75px;

	a {
		color: var(--header-color);
		font-family: var(--heading-font-family);
		font-weight: 600;
		font-size: 26px;
		line-height: 54px;
		border-bottom: 4px solid rgba(0 0 0 / 0%);
		padding: 0 0 5px;
		transition: all 0.3s ease-in-out;

		&:hover,
		&.active {
			color: var(--cta-color);
			border-color: var(--cta-color);
		}
	}
}

.nav-contact {
	align-self: flex-end;
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	justify-content: center;
	gap: 20px;
	padding: 25px 0;
	height: 30px;
	line-height: 30px;
	text-align: center;
	font-size: 22px;
	margin: 5px 16px 5px 0;

	svg {
		margin-right: 5px;
	}

	.socials {
		margin: 0;
		border-left: 1px solid #161616;
		padding-left: 15px;
	}
}

.nav-contact > a {
	color: var(--cta-color);
	display: block;
	font-weight: 300;
	margin: 6px 0 -2px;
}

.nav-contact > a i {
	margin: 0 10px 0 0;
}

.close-wrapper {
	align-self: flex-start;
}

.nav-close {
	color: #fff;
	cursor: pointer;
	font-size: 54px;
	padding: 0 0 0 12px;
}

/* Breadcrumbs */
.breadcrumbs ul {
	list-style: none;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	margin: 0 0 75px;
}

.breadcrumbs li {
	margin: 0 10px 0 0;
}

.breadcrumbs i {
	font-size: 11px;
}

.breadcrumbs a {
	color: var(--body-color);
}

.breadcrumbs a.active {
	color: var(--cta-color);
}

@media (max-height: 740px) {
	.nav-container {
		overflow-y: scroll;
	}

	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		z-index: 1;
	}

	.main-nav {
		z-index: 2;
		position: relative;
	}
}

@media (max-width: 920px) {
	.reserve-now {
		padding: 0;
		border-radius: 0 !important;
		min-width: 0;

		svg {
			margin: 0;
		}
	}

	.nav-buttons .lang-select,
	:deep(.socials .whatsapp) {
		display: none;
	}

	.menu-button {
		justify-content: center;
	}

	.lang-nav {
		display: block;
	}

	.close-wrapper {
		align-self: flex-end;
	}
}

@media (max-width: 800px) {
	.lang-nav {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		margin-top: 30px;
	}

	.main-nav {
		a {
			font-size: 22px;
			line-height: 44px;
		}
	}
}

@media (max-width: 600px) {
	.nav-container {
		padding: 10px 25px 100px;
	}

	.main-nav,
	.contact-info,
	.lang-nav {
		width: 100%;
	}

	nav.main {
		.flex-row {
			padding: 0;
		}
	}

	.main-nav {
		padding-top: 150px;
	}

	.nav-contact {
		padding: 25px 0 75px;
		height: auto;
	}

	nav.main.scroll,
	nav.main.no-slider {
		padding: 18px 6px;
	}

	.contact-info {
		margin: 25px auto 0;
	}

	.lang-nav {
		margin-top: 0;
	}

	nav .buttons .button {
		svg {
			margin-right: 0;
			font-size: 16px;
		}

		span {
			display: none;
		}
	}
}
</style>
