<template>
	<div id="top" class="container" :class="{ 'no-heading-image': !page.headingImage, subpage: page.slug !== 'index' }">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />
		</Head>

		<navigation />

		<slot />

		<footers />

		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image-webp="defaults[locale].popUp.imageWebp"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content"
		/>
		<cookie-consent v-if="defaults[locale].cookieConsent.published" :item="defaults[locale].cookieConsent" />
		<to-top />

		<client-only>
			<component
				:is="'script'"
				:id="'guestline-tag'"
				:type="'text/javascript'"
				:data-group-id="'GOUDA'"
				:data-lng="locale"
				:async="'async'"
				:src="'https://gxptag.guestline.net/static/js/tag.js'"
			/>
		</client-only>
	</div>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchDefaults();
await fetchPage();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.pageYOffset >= 50) {
		fixedElements.forEach((el) => el.classList.add('scroll'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('scroll'));
	}
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
	handleScroll();
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
	handleScroll();
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo-city-hotel-gouda-full-red.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>
