<template>
	<div class="socials" :class="theme">
		<div v-for="medium in socials" :key="medium.ID">
			<a
				v-if="medium.platform == 'whatsapp'"
				:class="medium.platform"
				:aria-label="medium.platform"
				:href="medium.url + '&lang=' + locale"
				target="_blank"
			>
				<font-awesome-icon :icon="`fa-brands fa-${medium.platform}`" size="1x" />
			</a>
			<a v-else :class="medium.platform" :aria-label="medium.platform" :href="medium.url" target="_blank">
				<font-awesome-icon v-if="medium.platform == 'twitter'" icon="fa-brands fa-x-twitter" size="1x" />
				<font-awesome-icon v-else :icon="`fa-brands fa-${medium.platform}`" size="1x" />
			</a>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	theme: { type: String, default: 'light' },
	socials: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.socials .tripadvisor {
	background: url('~/assets/images/tripadvisor-small-white.png') no-repeat center center var(--cta-color);
	background-size: 22px;
	width: 30px;
	height: 30px;
	line-height: 30px;
}

.socials .tripadvisor:hover {
	background: url('~/assets/images/tripadvisor-small-white.png') no-repeat center center var(--cta-color-hover);
	background-size: 22px;
}

.socials {
	display: flex;
	align-items: center;
	place-content: center flex-start;

	a {
		color: #fff;
		display: flex;
		align-items: center;
		place-content: center center;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 22px;
		margin: 5px 16px 5px 0;
		text-decoration: none;

		&:hover {
			color: var(--cta-color);
		}
	}

	&.dark {
		a {
			color: var(--cta-color);

			&:hover {
				color: var(--cta-color-hover);
			}
		}
	}
}

nav.main.no-slider .nav-buttons .socials a {
	color: var(--subtitle-color);
}

@media (max-width: 680px) {
	footer .socials {
		justify-content: center;
	}
}
</style>
