<template>
	<a class="to-top" @click="scrollToTop()" />
</template>

<script setup>
const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.to-top');
	if (window.pageYOffset >= 350) {
		fixedElements.forEach((el) => el.classList.add('show'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('show'));
	}
};

const scrollToTop = () => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: 'smooth',
	});
};

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	opacity: 0;
	color: #333;
	text-align: center;
	line-height: 32px;
	cursor: pointer;
	z-index: 100;
	background: url('~/assets/images/icon-scroll-up-black.png') no-repeat center center;
	width: 32px;
	height: 31px;
	background-size: 32px;

	&:hover {
		color: var(--cta-color);
	}

	&.show {
		opacity: 1;
	}
}

@media (max-width: 600px) {
	.to-top {
		bottom: 70px;
	}
}
</style>
