<template>
	<transition name="popup">
		<client-only>
			<div v-if="!popupSeen" class="popup">
				<div :class="{ 'no-img': !image }" class="popup-row">
					<div v-if="image" class="popup-image">
						<picture>
							<source v-if="imageWebp" :srcset="imageWebp" type="image/webp" />
							<source :srcset="image" />
							<img :src="image" :alt="imageAlt" />
						</picture>
					</div>
					<div class="popup-body">
						<div class="close-popup popup-button" @click="setPopupSeen">
							<font-awesome-icon icon="fal fa-times" size="1x" />
						</div>
						<div class="popup-content">
							<div v-parse-links v-html="content" />
						</div>
					</div>
				</div>
				<div class="popup-background close-popup" @click="setPopupSeen" />
			</div>
		</client-only>
	</transition>
</template>

<script setup>
const route = useRoute();

defineProps({
	image: { type: String, default: '' },
	imageWebp: { type: String, default: '' },
	content: { type: String, default: '' },
});

const popupSeen = ref(false);

onMounted(() => {
	if (window.localStorage && window.localStorage.getItem('popupSeen')) {
		popupSeen.value = true;
	} else if (window.localStorage) {
		window.localStorage.setItem('popupSeen', 'true');
	}
});

const setPopupSeen = () => {
	if (window.localStorage) {
		window.localStorage.setItem('popupSeen', 'true');
	}
	popupSeen.value = true;
};

watch(
	() => route.fullPath,
	() => {
		popupSeen.value = true;
	},
);
</script>

<style lang="scss" scoped>
.popup-enter-active {
	transition: opacity 0.4s ease-out;
}

.popup-leave-active {
	transition: opacity 0.2s ease-out;
}

.popup-leave-to,
.popup-enter {
	opacity: 0;
}

.popup {
	position: fixed;
	inset: 0;
	z-index: 99999;

	.popup-background {
		background: rgb(0 0 0 / 50%);
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	.popup-row {
		display: flex;
		flex-flow: wrap row;
		justify-content: space-between;
		align-items: stretch;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 900px;
		z-index: 3;
		width: 100%;

		&.no-img {
			justify-content: center;
		}
	}

	.popup-image {
		width: 50%;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
			aspect-ratio: 3 / 2;
		}
	}

	.popup-body {
		position: relative;
		background: var(--primary-background-color);
		z-index: 2;
		width: 50%;
		padding: 70px 60px;

		.popup-button {
			position: absolute;
			top: 17px;
			right: 24px;
			color: #000;
			cursor: pointer;
			font-size: 30px;
		}

		.popup-content {
			float: left;
			width: 100%;
			padding: 15px 0 0;
		}
	}
}

@media (max-width: 900px) {
	.popup {
		.popup-row {
			flex-flow: column wrap;
			max-width: 500px;
			width: 90%;
		}

		.popup-body {
			padding: 30px;
		}

		.popup-image,
		.popup-body {
			width: 100%;
		}
	}
}

@media (max-width: 780px) {
	.popup {
		.popup-body {
			padding: 20px 30px;
		}
	}
}
</style>
